<template>
  <div id="form">
    <el-form
      :ref="formName"
      :model="query"
      :rules="queryRule"
      label-width="120px"
      label-position="left">
      <el-form-item
        label="名称"
        prop="title">
        <el-input v-model="query.title" />
      </el-form-item>
      <el-form-item
        label="生效时间"
        required>
        <el-col :span="7">
          <el-form-item prop="start_at">
            <el-date-picker
              v-model="query.start_at"
              type="datetime"
              placeholder="选择开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="left"
              style="width: 100%">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="2">至</el-col>
        <el-col :span="7">
          <el-form-item prop="end_at">
            <el-date-picker
              v-model="query.end_at"
              type="datetime"
              placeholder="选择結束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="left"
              style="width: 100%">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item
        label="排序"
        prop="sort"
        align="left">
        <el-input-number style="width: 200px;" :min="1" v-model="query.sort" />
      </el-form-item>
      <el-form-item
        label="内文"
        prop="content">
        <tinymce-editor v-model="query.content" type="news" :id="query.id" />
      </el-form-item>
      <el-form-item
        label="标题图示"
        prop="upload"
        ref="uploadValidate">
        <template>
          <div class="upload-colum">
            <el-upload
              class="upload-demo"
              ref="refPhoto"
              action=""
              :on-remove="handleRemovePhoto"
              :on-change="handlePhoto"
              accept="image/jpg,image/png,image/jpeg"
              :file-list="photoList"
              list-type="picture"
              :auto-upload="false"
              :disabled="photoBtnDisable"
              :multiple="false"
              :limit="1">
              <el-button
                size="medium"
                type="primary"
                :disabled="photoBtnDisable">
                  <i class="el-icon-upload el-icon--right"></i> 上传图片
                </el-button>
              <div slot="tip" class="el-upload__tip">
                ＊限制上傳 1 張照片，支持 jpg, png, jpeg 格式，尺寸135*110
              </div>
              <div slot="file" slot-scope="{file}">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url" alt=""
                >
                <a class="el-upload-list__item-name">
                  {{file.name}}
                </a>
                <label class="el-upload-list__item-status-label">
                  <i class="el-icon-upload-success el-icon-check"></i>
                </label>
                <i class="el-icon-close"
                  @click="handleRemovePhoto"></i>
              </div>
            </el-upload>
          </div>
        </template>
      </el-form-item>
      <el-form-item
        label="广告图示"
        prop="upload_bg"
        ref="uploadBgValidate">
        <template>
          <div class="upload-colum">
            <el-upload
              class="upload-demo"
              ref="refPhotoBg"
              action=""
              :on-remove="handleRemovePhotoBg"
              :on-change="handlePhotoBg"
              accept="image/jpg,image/png,image/jpeg"
              :file-list="photoBgList"
              :disabled="photoBgBtnDisable"
              list-type="picture"
              :auto-upload="false"
              :multiple="false"
              :limit="1">
              <el-button
                size="medium"
                type="primary"
                :disabled="photoBgBtnDisable">
                  <i class="el-icon-upload el-icon--right"></i> 上传图片
                </el-button>
              <div slot="tip" class="el-upload__tip">
                ＊限制上傳 1 張照片，支持 jpg, png, jpeg 格式，尺寸812*400
              </div>
              <div slot="file" slot-scope="{file}">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url" alt=""
                >
                <a class="el-upload-list__item-name">
                  {{file.name}}
                </a>
                <label class="el-upload-list__item-status-label">
                  <i class="el-icon-upload-success el-icon-check"></i>
                </label>
                <i class="el-icon-close"
                  @click="handleRemovePhotoBg"></i>
              </div>
            </el-upload>
          </div>
        </template>
      </el-form-item>
      <el-form-item
        label="备注"
        prop="remark">
        <el-input 
          maxlength="200"
          show-word-limit
          type="textarea" 
          :autosize="{ minRows: 3, maxRows: 6 }"
          v-model="query.remark" />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer">
      <el-button @click="handleCancel">
        取消
      </el-button>
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="handleSubmit">
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { benefitDetail, benefitEdit } from '@/api/admin/benefit'
import TinymceEditor from '@/components/admin/tinymce-editor.vue'
// import { adminLogout } from '@/utils/admin'

export default {
  components: {
    TinymceEditor
  },
  data () {
    return {
      formName: 'form',
      dateRange: '',
      photoList: [],
      photoBgList: [],
      photoBtnDisable: false,
      photoBgBtnDisable: false,
      saveLoading: false,
      query: {
        id: '',
        title: '',
        sort: '',
        start_at: '',
        end_at: '',
        content: '',
        is_visible: 1,
        upload: null,
        upload_bg: null
      },
      queryRule: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          }
        ],
        sort: [
          {
            required: true,
            message: '请输入排序數字',
            trigger: 'blur'
          }
        ],
        start_at: [
          {
            required: true,
            message: '请选择开始时间',
            trigger: 'blur'
          }
        ],
        end_at: [
          {
            required: true,
            message: '请选择结束时间',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入内文',
            trigger: 'blur'
          }
        ],
        remark: [
          {
            required: true,
            message: '请输入备注',
            trigger: 'blur'
          }
        ],
        upload: [
          {
            required: true,
            validator: this.validatePhoto,
            tribber: 'blur'
          }
        ],
        upload_bg: [
          {
            required: true,
            validator: this.validatePhotoBg,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    init () {
      benefitDetail(this.query.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.query = res.data
            window.tinymce.editors[0].setContent(this.query.content)
            this.setUploadList()
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    setUploadList () {
      const imgUrlSplit = this.query.photo.split('/')
      const imgUrlSplit2 = this.query.photo_bg.split('/')
      const imgName = imgUrlSplit[imgUrlSplit.length - 1]
      const imgName2 = imgUrlSplit[imgUrlSplit2.length - 1]
      this.photoList.push({
        name: imgName,
        url: this.query.photo
      })
      this.photoBgList.push({
        name: imgName2,
        url: this.query.photo_bg
      })
      this.photoBtnDisable = true
      this.photoBgBtnDisable = true
    },
    handleCancel () {
      this.$router.go(-1)
    },
    handleSubmit () {
      this.$refs[this.formName].validate(valid => {
        if (valid)
          this.saveData()
      })
    },
    saveData () {
      this.saveLoading = true
      benefitEdit(this.query.id, this.query)
        .then(res => {
          this.saveLoading = false
          switch (res.status) {
            case resStatus.OK:
              this.handleCancel()
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.saveLoading = false
          this.$message.error(err)
        })
    },
    handleRemovePhoto (file, fileList) {
      this.query.upload = null
      if (this.query.photo) {
        this.query.photo = null
      }
      this.photoList.pop()
      this.$refs.refPhoto.clearFiles()
      this.photoBtnDisable = false
    },
    handleRemovePhotoBg (file, fileList) {
      this.query.upload_bg = null
      if (this.query.photo_bg) {
        this.query.photo_bg = null
      }
      this.photoBgList.pop()
      this.$refs.refPhotoBg.clearFiles()
      this.photoBgBtnDisable = false
    },
    handlePhoto (file, fileList) {
      if (this.query.upload) {
        this.$refs.refPhoto.abort()
        this.$message.error('最多上传一张图片！')
        return false
      }
      this.varifyPhotoMeasure(file).then(obj => {
        if (obj) {
          if (obj.message) {
            this.$message.error(obj.message)
            this.$refs.refPhoto.clearFiles()
          } else {
            this.query.upload = file.raw
            this.photoBtnDisable = true
            this.$refs.uploadValidate.clearValidate()
          }
        } else {
          this.$message.error('发生错误')
        }
      })
    },
    handlePhotoBg (file) {
      if (this.query.upload_bg) {
        this.$refs.refPhotoBg.abort()
        this.$message.error('最多上传一张图片！')
        return false
      }
      this.varifyPhotoMeasureBg(file).then(obj => {
        if (obj) {
          if (obj.message) {
            this.$message.error(obj.message)
            this.$refs.refPhotoBg.clearFiles()
          } else {
            this.query.upload_bg = file.raw
            this.photoBgBtnDisable = true
            this.$refs.uploadBgValidate.clearValidate()
          }
        } else {
          this.$message.error('发生错误')
        }
      })
    },
    varifyPhotoMeasure (file) {
      const filePath = file.url
      var width = 0
      var height = 0
      if (filePath) {
        const image = new Image()
        image.src = window.URL.createObjectURL(file.raw)
        return new Promise(resolve => {
          image.onload = function () {
            width = this.width
            height = this.height
            let resObj = {}
            if (width !== 135 && height !== 110) {
              resObj = { message: '图片尺寸应为：135*110' }
            } else {
              resObj = { file: file }
            }
            // resObj = { file: file }
            resolve(resObj)
          }
        })
      } else {
        return { message: '上传失敗' }
      }
    },
    varifyPhotoMeasureBg (file) {
      const filePath = file.url
      var width = 0
      var height = 0
      if (filePath) {
        const image = new Image()
        image.src = window.URL.createObjectURL(file.raw)
        return new Promise(resolve => {
          image.onload = function () {
            width = this.width
            height = this.height
            let resObj = {}
            if (width !== 812 && height !== 400) {
              resObj = { message: '图片尺寸应为：812*400' }
            } else {
              resObj = { file: file }
            }
            // resObj = { file: file }
            resolve(resObj)
          }
        })
      } else {
        return { message: '上传失敗' }
      }
    },
    validatePhoto (rule, value, callback) {
      if (!this.query.upload) {
        if (!this.query.photo) {
          callback(new Error('请上傳标题图示'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    validatePhotoBg (rule, value, callback) {
      if (!this.query.upload_bg) {
        if (!this.query.photo_bg) {
          callback(new Error('请上傳广告图示'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
  },
  created () {
    this.query.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#form{
  max-width: calc((100vw - 200px) * 0.8);
  .effect-time-colum{
    text-align: left;
    & span{
      display: inline-block;
      margin: 0 10px;
    }
  }
  .upload-colum{
    text-align: left;
    .el-upload-list--picture .el-upload-list__item.is-ready .el-upload-list__item-name {
      line-height: 70px;
      margin-top: 0;
    }
    .el-upload-list__item.is-ready .el-upload-list__item-status-label {
      display: block;
    }
    .el-upload-list--picture .el-upload-list__item-thumbnail{
      object-fit: contain !important;
    }
  }
}
.el-input-number {
  width: 100%;
}
</style>
